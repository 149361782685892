import { FC } from 'react'
import { classNames } from './utils/classnames'
import { InstagramSVG } from './InstagramSVG'
import { Post, Product } from './interfaces/posts'

const dateFormatter = Intl.DateTimeFormat('ja', {
  dateStyle: 'long',
})

const formatter = Intl.NumberFormat('en', { notation: 'compact' })

interface PostDetailsSlideProps {
  post: Post
  shopifyProducts?: Product[]
  customProducts?: Product[]
  onProductClick?: (product: Product) => void
}

const PostDetailsSlide: FC<PostDetailsSlideProps> = ({
  post,
  shopifyProducts,
  customProducts,
  onProductClick,
}) => {
  const imageSection = (
    <div className='lemon-overflow-hidden lemon-bg-gray-100 md:lemon-rounded-lg'>
      <img
        src={post.mediaUrl}
        alt='instagram post'
        className='lemon-object-cover lemon-object-center'
      />
    </div>
  )

  const videoSection = (
    <div className='lemon-bg-gray-200 group-hover:lemon-opacity-80 '>
      <video className='lemon-object-cover lemon-object-center' autoPlay loop muted>
        <source src={post.mediaUrl} type={`video/${post.mediaUrl?.split(".").pop()}`} />
        Browser does not support the video tag.
      </video>
    </div>
  )

  const leftSection = (
    <div className='sm:lemon-col-span-4 lg:lemon-col-span-5'>
      {post.mediaType === 'VIDEO' || post.mediaType === 'REEL_VIDEO' || post.mediaType === 'STORY_VIDEO' ? videoSection: imageSection}
    </div>
  )
  const usernameRow = (
    <h2 className='lemon-flex lemon-flex-row lemon-items-center lemon-text-[18px] lemon-leading-[28px] lemon-font-bold lemon-text-gray-900 sm:lemon-pr-12 lemon-w-full lemon-gap-x-2'>
      {post.photoUrl ? (
        <img
          src={post.photoUrl}
          alt=''
          className='lemon-inline lemon-w-10 lemon-rounded-full lemon-border-red-600 lemon-border-2 lemon-border-spacing-8 lemon-p-0.5'
        />
      ) : (
        <InstagramSVG />
      )}
      <div className='lemon-flex lemon-flex-wrap lemon-justify-between lemon-grow lemon-items-center'>
        <p className='lemon-text-base'>{post.username}</p>

        <p className='lemon-text-sm'>
          {post.followersCount &&
            `${formatter.format(post.followersCount)} followers`}
        </p>
      </div>
    </h2>
  )

  const timestampRow = (
    <p className='lemon-text-[14px] lemon-leading-[20px] lemon-text-gray-900'>
      {dateFormatter.format(new Date(post.timestamp))}
    </p>
  )

  const captionRow = (
    <div className='lemon-relative'>
      <p
        className={classNames(
          'lemon-pr-4 lemon-pb-8 lemon-whitespace-pre-line lemon-text-[14px] lemon-leading-[20px] lemon-text-gray-700 lemon-max-h-full lemon-h-[28rem] lemon-overflow-y-auto lemon-line-clamp-none',
        )}
      >
        {post.caption}
      </p>
    </div>
  )

  const shopifyProductsSection = !!shopifyProducts?.length && (
    <section aria-labelledby='add-to-cart' className='lemon-my-3'>
      <div className='lemon-mt-6 lemon-mb-2'>▼紹介アイテムの詳細はこちら！</div>
      <div className='lemon-grid lemon-grid-cols-1 lemon-justify-items-center lemon-gap-y-10 lemon-gap-x-6 lemon-col-auto xl:lemon-gap-x-8 lg:lemon-grid-cols-2'>
        {/* <div className='lemon-mt-0 lemon-grid lemon-grid-cols-1 lemon-gap-y-12 sm:lemon-grid-cols-2 sm:lemon-gap-x-6 lg:lemon-grid-cols-4 xl:lemon-gap-x-8'> */}
        {shopifyProducts?.map(product => (
          <div key={product.id}>
            <div className='lemon-relative'>
              <div className='lemon-relative lemon-h-72 lemon-w-full lemon-overflow-hidden lemon-rounded-lg'>
                <img
                  src={product.imageUrl}
                  alt={product.title}
                  className='lemon-h-full lemon-w-full lemon-object-cover lemon-object-center'
                />
              </div>
              <div className='lemon-relative lemon-mt-4'>
                <h3 className='lemon-text-[14px] lemon-leading-[20px] lemon-font-medium lemon-text-gray-900'>
                  {product.title}
                </h3>
                {/* <p className='lemon-mt-1 lemon-text-[14px] lemon-leading-[20px] lemon-text-gray-500'>
                    {product.tags}
                  </p> */}
              </div>
              <div className='lemon-absolute lemon-inset-x-0 lemon-top-0 lemon-flex lemon-h-72 lemon-items-end lemon-justify-end lemon-overflow-hidden lemon-rounded-lg lemon-p-4'>
                <div
                  aria-hidden='true'
                  className='lemon-absolute lemon-inset-x-0 lemon-bottom-0 lemon-h-36 lemon-bg-gradient-to-t lemon-from-black lemon-opacity-50'
                />
                {/* <p className='lemon-relative lemon-text-[18px] lemon-leading-[28px] lemon-font-semibold lemon-text-white'>
                  {product.price}
                </p> */}
              </div>
            </div>
            <div className='lemon-mt-6'>
              <button
                className='lemon-relative lemon-flex lemon-items-center lemon-w-full lemon-justify-center lemon-rounded-md lemon-border lemon-border-transparent lemon-bg-gray-100 lemon-px-8 lemon-py-2 lemon-text-[14px] lemon-leading-[20px] lemon-font-medium lemon-text-gray-900 hover:lemon-bg-gray-200'
                onClick={() => onProductClick(product)}
              >
                商品ページへ
                <span className='lemon-sr-only'>, {product.title}</span>
              </button>
            </div>
          </div>
        ))}
        {/* </div> */}
      </div>
    </section>
  )

  const customProductsSection = !!customProducts?.length && (
    <section aria-labelledby='add-to-cart' className='lemon-my-3'>
      <div className='lemon-mt-6 lemon-mb-2'>▼紹介アイテムの詳細はこちら！</div>
      <div className='lemon-grid lemon-grid-cols-1 lemon-justify-items-center lemon-gap-y-10 lemon-gap-x-6 lemon-col-auto xl:lemon-gap-x-8'>
        {customProducts?.map(product => (
          <button
            type='button'
            onClick={() => onProductClick(product)}
            className='lemon-animate-pulse lemon-rounded-md lemon-bg-slate-600 lemon-py-2.5 lemon-px-3.5 lemon-text-[14px] lemon-leading-[20px] lemon-font-semibold lemon-text-white lemon-shadow-sm lemon-hover:bg-slate-500 lemon-focus-visible:outline lemon-focus-visible:outline-2 lemon-focus-visible:outline-offset-2 lemon-focus-visible:outline-slate-600'
          >
            商品ページへ
          </button>
        ))}
      </div>
    </section>
  )

  const rightSection = (
    <div className='sm:lemon-col-span-8 lg:lemon-col-span-7'>
      {usernameRow}

      <section aria-labelledby='information-heading' className='lemon-mt-1'>
        <h3 id='information-heading' className='lemon-sr-only'>
          Product information
        </h3>

        {timestampRow}

        <div className='lemon-mt-4'>
          <h4 className='lemon-sr-only'>Description</h4>
          {captionRow}
        </div>
      </section>

      {shopifyProductsSection}
      {customProductsSection}
    </div>
  )
  return (
    <div className='lemon-grid lemon-min-h-full sm:lemon-min-h-[32rem] lemon-w-full lemon-grid-cols-1 lemon-items-start lemon-gap-y-8 lemon-gap-x-6 sm:lemon-grid-cols-12 lg:lemon-gap-x-8'>
      {leftSection}
      {rightSection}
    </div>
  )
}

export default PostDetailsSlide
