import { FC } from 'react'

import { CommentSVG } from './CommentSVG'
import { HeartSVG } from './HeartSVG'
import { InstagramSVG } from './InstagramSVG'
import { Post } from './interfaces/posts'

const DEV = import.meta.env.DEV

const formatter = Intl.NumberFormat('en', { notation: 'compact' })

interface PostGridItemProps {
  post: Post
  index: number
  onClick: () => void
}

const PostGridItem: FC<PostGridItemProps> = ({ post, index, onClick }) => {
  const imageContainer = (
    <div className='lemon-bg-gray-200 group-hover:lemon-opacity-80 '>
      <img
        src={post.mediaUrl}
        alt='instagram post'
        className='lemon-aspect-[1/1] lemon-w-full lemon-object-cover lemon-object-center'
      />
    </div>
  )

  const videoContainer = (
    <div className='lemon-bg-gray-200 group-hover:lemon-opacity-80 '>
      { index === 0 ?
        <video className='lemon-aspect-[1/1.618] lemon-w-full lemon-object-cover lemon-object-center' autoPlay loop muted>
          <source src={post.mediaUrl} type={`video/${post.mediaUrl?.split(".").pop()}`} />
          Browser does not support the video {index} tag.
        </video>
        : <video className='lemon-aspect-[1/1.618] lemon-w-full lemon-object-cover lemon-object-center'>
            <source src={post.mediaUrl} type={`video/${post.mediaUrl?.split(".").pop()}`} />
            Browser does not support the video {index} tag.
          </video>
      }
    </div>
  )

  const instagramUsernameRow = (
    <h3 className='lemon-mb-2 lemon-text-sm lemon-font-medium lemon-text-gray-900'>
      <div className='lemon-flex lemon-flex-row lemon-items-center lemon-space-x-2'>
        {post.photoUrl ? (
          <img
            src={post.photoUrl}
            alt=''
            className='lemon-w-10 lemon-rounded-full lemon-border-red-600 lemon-border-2 lemon-border-spacing-8 lemon-p-0.5'
          />
        ) : (
          <InstagramSVG />
        )}

        <div className='lemon-flex lemon-flex-wrap lemon-justify-between lemon-grow lemon-items-center'>
          <p>{post.username}</p>
          <p className=' lemon-text-xs'>
            {post.followersCount &&
              `${formatter.format(post.followersCount)} followers`}
          </p>
        </div>
      </div>
    </h3>
  )

  const captionRow = (
    <div className='lemon-relative'>
      <p className='lemon-line-clamp-6 sm:lemon-line-clamp-5 lemon-text-[12px] lemon-leading-[16px] lemon-text-gray-500 after:lemon-absolute after:lemon-inset-x-0 after:lemon-bottom-0 after:lemon-h-5 after:lemon-bg-gradient-to-t after:lemon-from-white'>
        {post.caption}
      </p>
    </div>
  )

  const likesSection = (
    <div className='lemon-flex lemon-flex-row lemon-items-center lemon-space-x-1'>
      <HeartSVG />
      <p>{post.likeCount ? formatter.format(post.likeCount) : '-'}</p>
    </div>
  )

  const commentsSection = (
    <div className='lemon-flex lemon-flex-row lemon-items-center lemon-space-x-1'>
      <CommentSVG />
      <p>{post.commentsCount ? formatter.format(post.commentsCount) : '-'}</p>
    </div>
  )

  const buttonsRow = (
    <div className='lemon-mt-auto lemon-flex lemon-flex-1 lemon-flex-col lemon-justify-between lemon-pt-3 lemon-text-[14px] lemon-leading-[20px] lemon-font-medium lemon-text-gray-600'>
      <div className='lemon-flex lemon-flex-row lemon-justify-around'>
        {likesSection}
        {commentsSection}
      </div>
    </div>
  )

  const bottomContainer = (
    <div className='lemon-flex lemon-flex-1 lemon-flex-col lemon-p-4'>
      {instagramUsernameRow}
      {captionRow}
      {buttonsRow}
    </div>
  )

  return (
    <div
      onClick={() => onClick()}
      className='lemon-group lemon-relative lemon-flex lemon-cursor-pointer lemon-flex-col lemon-overflow-hidden lemon-rounded-lg lemon-border lemon-border-gray-200 lemon-bg-white'
    >
      {post.mediaType === 'VIDEO' || post.mediaType === 'REEL_VIDEO' || post.mediaType === 'STORY_VIDEO' ? videoContainer: imageContainer}
      {bottomContainer}
    </div>
  )
}

export { PostGridItem }
