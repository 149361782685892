import { FC } from 'react'
import { CommentSVG } from './CommentSVG'
import { Post } from './interfaces/post'
import { HeartSVG } from './HeartSVG'
import { InstagramSVG } from './InstagramSVG'

const formatter = Intl.NumberFormat('en', { notation: 'compact' })

interface PostRowItemProps {
  post: Post
  onClick?: () => void
}

const PostRowItem: FC<PostRowItemProps> = ({ post, onClick }) => {
  const instagramUsernameRow = (
    <h3 className='lemon-mb-2 lemon-text-[14px] lemon-leading-[20px] lemon-font-medium lemon-text-gray-900'>
      <p className='lemon-flex lemon-flex-row lemon-items-center'>
        <InstagramSVG />
        {post.username}
      </p>
    </h3>
  )

  const captionRow = (
    <div className='lemon-relative lemon-mt-2'>
      <p className='lemon-line-clamp-6 sm:lemon-line-clamp-5 lemon-text-[12px] lemon-leading-[16px] lemon-text-gray-500 after:lemon-absolute after:lemon-inset-x-0 after:lemon-bottom-0 after:lemon-h-5 after:lemon-bg-gradient-to-t after:lemon-from-white'>
        {post.caption}
      </p>
    </div>
  )

  const likesSection = (
    <div className='lemon-flex lemon-flex-row lemon-items-center lemon-space-x-1'>
      <HeartSVG />
      <p>{post.likeCount ? formatter.format(post.likeCount) : '-'}</p>
    </div>
  )

  const commentsSection = (
    <div className='lemon-flex lemon-flex-row lemon-items-center lemon-space-x-1'>
      <CommentSVG />
      <p>{post.commentsCount ? formatter.format(post.commentsCount) : '-'}</p>
    </div>
  )

  const buttonsRow = (
    <div className='lemon-flex lemon-min-w-[160px] lemon-flex-row lemon-justify-evenly lemon-space-x-6 lemon-py-3 lemon-text-[14px] lemon-leading-[20px] lemon-font-medium lemon-text-gray-600'>
      {likesSection}
      {commentsSection}
    </div>
  )

  const bottomContainer = (
    <div className='lemon-flex lemon-flex-1 lemon-flex-col lemon-justify-between lemon-bg-transparent lemon-p-4 lemon-pb-1'>
      <div className='lemon-flex-1'>
        {instagramUsernameRow}
        {captionRow}
      </div>

      {buttonsRow}
    </div>
  )

  const imageContainer = (
    <div className='lemon-flex-shrink-0 lemon-overflow-hidden'>
      <img
        src={post.mediaUrl}
        alt='instagram post'
        className='lemon-h-[12rem] lemon-w-full lemon-object-cover'
      />
    </div>
  )

  return (
    <li
      className='lemon-group lemon-relative lemon-cursor-pointer lemon-list-none lemon-snap-center'
      onClick={onClick}
    >
      <div className='lemon-overflow-hidden lemon-rounded-lg lemon-border lemon-border-gray-200 lemon-bg-white'>
        {imageContainer}
        {bottomContainer}
      </div>
    </li>
  )
}

export { PostRowItem }
