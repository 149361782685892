import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const PostGridSkeleton: FC = () => {
  const instagramUsernameRow = (
    <h3 className='lemon-mb-2 lemon-text-[14px] lemon-leading-[20px]'>
      <Skeleton key={'instagramUsernameRow'} />
    </h3>
  )

  const captionRow = (
    <div className='lemon-relative'>
      <div className='lemon-text-xs'>
        {/* <Skeleton count={3} key={'captionRow'} /> */}
      </div>
    </div>
  )

  const likesSection = (
    <div className='lemon-w-40 lemon-text-[18px] lemon-leading-[28px]'>
      <Skeleton height={'1.5rem'} key={'likesSection'} />
    </div>
  )

  const buttonsRow = (
    <div className='lemon-mt-auto lemon-flex lemon-flex-1 lemon-flex-col lemon-justify-between lemon-pt-3 lemon-text-[14px] lemon-leading-[20px]'>
      <div className='lemon-flex lemon-flex-row lemon-justify-around'>
        {likesSection}
      </div>
    </div>
  )

  const imageContainer = (
    <div className='lemon-h-40 sm:lemon-h-48'>
      <Skeleton
        className='lemon-h-full lemon-w-full lemon-leading-[0]'
        style={{ lineHeight: 'unset' }}
        key={'imageContainer'}
      />
    </div>
  )

  const bottomContainer = (
    <div className='lemon-flex lemon-flex-1 lemon-flex-col lemon-p-4'>
      {instagramUsernameRow}
      {captionRow}
      {buttonsRow}
    </div>
  )

  return (
    <div className='lemon-relative lemon-flex lemon-w-full lemon-flex-grow lemon-justify-center'>
      <div className='lemon-group lemon-w-full lemon-min-w-[6rem] lemon-max-w-[22rem] lemon-cursor-pointer lemon-overflow-hidden lemon-rounded-lg lemon-border lemon-border-gray-200 lemon-bg-white sm:lemon-max-w-[20rem]'>
        {imageContainer}
        {/* {bottomContainer} */}
      </div>
    </div>
  )
}

export { PostGridSkeleton }
