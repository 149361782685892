import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const PostRowSkeleton: FC = () => {
  const instagramUsernameRow = (
    <h3 className='lemon-mb-2 lemon-text-[14px] lemon-leading-[20px]'>
      <Skeleton key={'instagramUsernameRow'} />
    </h3>
  )

  const captionRow = (
    <div className='lemon-relative lemon-mt-2'>
      <p className='lemon-text-xs'>
        {/* <Skeleton count={3} key={'captionRow'} /> */}
      </p>
    </div>
  )

  const likesSection = (
    <div className='lemon-w-40 lemon-text-[18px] lemon-leading-[28px]'>
      <Skeleton height={'1.5rem'} key={'likesSection'} />
    </div>
  )

  const buttonsRow = (
    <div className='lemon-mt-auto lemon-flex lemon-flex-1 lemon-flex-col lemon-justify-between lemon-pt-3 lemon-text-[14px] lemon-leading-[20px]'>
      <div className='lemon-flex lemon-flex-row lemon-justify-around'>
        {likesSection}
      </div>
    </div>
  )

  const imageContainer = (
    <div className='lemon-h-40 lemon-flex-shrink-0 lemon-overflow-hidden'>
      <Skeleton
        className='lemon-h-full lemon-w-full lemon-leading-[0]'
        style={{ lineHeight: 'unset' }}
        key={'imageContainer'}
      />
    </div>
  )

  const bottomContainer = (
    <div className='lemon-flex lemon-flex-1 lemon-flex-col lemon-justify-between lemon-p-4 lemon-pb-3'>
      <div className='lemon-flex-1'>
        {instagramUsernameRow}
        {captionRow}
      </div>

      {buttonsRow}
    </div>
  )

  return (
    <li className='lemon-lemon-group lemon-relative lemon-cursor-pointer lemon-list-none snap-center'>
      <div className='lemon-overflow-hidden lemon-rounded-lg lemon-border lemon-border-gray-200 lemon-bg-white'>
        {imageContainer}
        {bottomContainer}
      </div>
    </li>
  )
}

export { PostRowSkeleton }
