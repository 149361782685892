import './index.css'
import 'react-loading-skeleton/dist/skeleton.css'
import ReactDOM from 'react-dom/client'
import UGCRoot from './ugc-root'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import $ from 'jquery'
import _ from 'lodash'
// import _ from 'underscore'
let ugcRoot: ReactDOM.Root | null = null

async function startUGCComponent() {
  await new Promise(resolve => setTimeout(resolve, 1000))
  console.log('🍋 Starting Lemon Square UGC component...')

  const campaignElement = window.document.getElementById('lemon-square-ugc')
  console.log('🍋 UGC container', campaignElement)
  if (!campaignElement) return

  const template = campaignElement.getAttribute('template')
  const designMode = campaignElement.getAttribute('design_mode')
  const host = campaignElement.getAttribute('host')
  const path = campaignElement.getAttribute('path')
  const pathType = campaignElement.getAttribute('path_type')
  const locale = campaignElement.getAttribute('locale')
  const canonicalUrl = campaignElement.getAttribute('canonical_url')
  const currentTags = campaignElement.getAttribute('current_tags')
  const collectionId = campaignElement.getAttribute('collection_id')
  const productId = campaignElement.getAttribute('product_id')
  const variantId = campaignElement.getAttribute('variant_id')
  const handle = campaignElement.getAttribute('handle')
  const websiteId = campaignElement.getAttribute('website_id')
  const ugcSetId = campaignElement.getAttribute('ugc_set_id')
  const header = campaignElement.getAttribute('header')
  const subheader = campaignElement.getAttribute('subheader')
  const grid = campaignElement.getAttribute('grid')
  const largeX = campaignElement.getAttribute('large_x')
  const largeY = campaignElement.getAttribute('large_y')
  const midX = campaignElement.getAttribute('mid_x')
  const midY = campaignElement.getAttribute('mid_y')
  const smallX = campaignElement.getAttribute('small_x')
  const smallY = campaignElement.getAttribute('small_y')

  function parseString(value: string | null | undefined): string | undefined {
    return value || undefined
  }

  function parseBoolean(value: string | null | undefined): boolean {
    if (value && value.toLowerCase().trim() === 'true') {
      return true
    }
    if (value === '') {
      return true
    }
    return false
  }

  function parseNumber(value: string | null | undefined): number | undefined {
    if (value === null || value === undefined) {
      return undefined
    }
    return Number(value)
  }

  const queryClient = new QueryClient()
  // Call to make sure we can use jQuery and Underscore
  $.ajaxSetup({})
  _.template('')

  ugcRoot = ReactDOM.createRoot(campaignElement as HTMLElement)
  ugcRoot.render(
    <QueryClientProvider client={queryClient}>
      <UGCRoot
        template={parseString(template)}
        designMode={parseBoolean(designMode)}
        host={parseString(host)}
        path={parseString(path)}
        pathType={parseString(pathType)}
        locale={locale.toString()}
        canonicalUrl={parseString(canonicalUrl)}
        currentTags={currentTags.toString()}
        collectionId={parseString(collectionId)}
        productId={parseString(productId)}
        variantId={parseString(variantId)}
        handle={parseString(handle)}
        websiteId={parseString(websiteId)}
        ugcSetId={parseString(ugcSetId)}
        header={parseString(header)}
        subheader={parseString(subheader)}
        grid={parseBoolean(grid)}
        smallX={parseNumber(smallX)}
        smallY={parseNumber(smallY)}
        midX={parseNumber(midX)}
        midY={parseNumber(midY)}
        largeX={parseNumber(largeX)}
        largeY={parseNumber(largeY)}
      />
    </QueryClientProvider>,
  )
}

async function startUgcApp(window: Window) {
  console.log('🍋 Starting Lemon Square UGC app...')

  const { location, document, history } = window
  const { pathname, search } = location

  let currentUrl = `${pathname}${search}`
  let currentRef = document.referrer

  const handlePush = async (state, title, url) => {
    if (!url) return

    currentRef = currentUrl
    const newUrl = url.toString()

    if (newUrl.substring(0, 4) === 'http') {
      currentUrl = '/' + newUrl.split('/').splice(3).join('/')
    } else {
      currentUrl = newUrl
    }

    if (currentUrl !== currentRef) {
      if (ugcRoot) {
        ugcRoot.unmount()
        ugcRoot = null
      }

      await startUGCComponent()
    }
  }

  const hook = (_this, method, callback) => {
    const orig = _this[method]

    return (...args) => {
      callback.apply(null, args)

      return orig.apply(_this, args)
    }
  }

  // track history changes
  history.pushState = hook(history, 'pushState', handlePush)
  history.replaceState = hook(history, 'replaceState', handlePush)

  await startUGCComponent()
}

if (window !== undefined) {
  startUgcApp(window)
} else {
  console.log('❌ Window is not available.')
}
