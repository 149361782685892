import { FC, Fragment, useEffect, useRef, useState } from 'react'

import PostDetailsSlide from './post-details-slide'
import { PostGridItem } from './post-grid-item'
import { PostRowItem } from './post-row-item'

import { Dialog, Transition } from '@headlessui/react'
import { Navigation, Pagination, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { classNames } from './utils/classnames'

import { XMarkIcon } from '@heroicons/react/24/outline'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { PostGridSkeleton } from './post-grid-skeleton'
import { PostRowSkeleton } from './post-row-skeleton'
import { Post } from './interfaces/posts'

/**
 * @argument data (dataRaw): Shopify product data from Shopify's API.
 * @argument header: Title for the UGC set.
 * @argument subheader: Subtitle for the UGC set.
 * @argument lemon-grid: Boolean to determine if the UGC set is a lemon-grid or a carousel.
 * @argument smallX: Number of items to show in the UGC set on small screens (up to 2).
 * @argument smallY: Number of items to show in the UGC set on small screens.
 * @argument midX: Number of items to show in the UGC set on mid screens (up to 4).
 * @argument midY: Number of items to show in the UGC set on mid screens.
 * @argument largeX: Number of items to show in the UGC set on large screens (up to 6).
 * @argument largeY: Number of items to show in the UGC set on large screens.
 */

interface UGCRootProps {
  template?: string
  designMode?: boolean
  host?: string
  path?: string
  pathType?: string
  locale?: string
  canonicalUrl?: string
  currentTags?: string
  collectionId?: string
  productId?: string
  variantId?: string
  handle?: string
  websiteId?: string
  ugcSetId?: string
  header?: string
  subheader?: string
  grid: boolean
  smallX?: number
  smallY?: number
  midX?: number
  midY?: number
  largeX?: number
  largeY?: number
}

const UGCRoot: FC<UGCRootProps> = ({
  template,
  designMode,
  host,
  path,
  pathType,
  locale,
  // canonicalUrl,
  currentTags,
  collectionId,
  productId,
  variantId,
  websiteId,
  ugcSetId,
  header,
  subheader,
  grid = true,
  smallX = 2,
  smallY = 2,
  midX = 4,
  midY = 2,
  largeX = 6,
  largeY = 2,
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [initialSlide, setInitialSlide] = useState(-1)
  const [allElements, setAllElements] = useState(false)

  const panelOpenDuration = useRef<number | undefined>()

  let params = new URLSearchParams('')

  params.append('template', template ?? '')
  params.append('designMode', designMode.toString())
  params.append('path', path)
  params.append('pathType', pathType)
  params.append('locale', locale ?? '')
  params.append('collectionId', collectionId ?? '')
  params.append('productId', productId ?? '')
  params.append('variantId', variantId ?? '')
  params.append('websiteId', websiteId ?? '')
  params.append('ugcSetId', ugcSetId ?? '')

  const { isLoading, error, data } = useQuery({
    queryKey: ['posts'],
    queryFn: async () => {
      const { data } = await axios.get(
        `https://api.lemon.cchan.tv/ugc/ugc-set?${params.toString()}`,
        // `http://localhost:7700/ugc/ugc-set?${params.toString()}`,
      )
      return data as Post[]
    },
    // !IMPORTANT: Conflicts with shopify
    // queryFn: () =>
    //   fetch(`https://api.lemon.cchan.tv/ugc/ugc-set?ugcSetId=1`).then(res =>
    //     res.json(),
    //   ),
  })

  useEffect(() => {
    if (!websiteId || websiteId.length === 0) return

    const DOMAIN = 'https://analytics.lemon.cchan.tv'
    const TRACKER_SCRIPT_NAME = 'umami'
    const AUTO_TRACK = 'false'
    const DO_NOT_TRACK = 'false'
    const CACHE = 'true'

    const script = document.createElement('script')
    script.setAttribute('src', `${DOMAIN}/${TRACKER_SCRIPT_NAME}.js`)
    script.setAttribute('data-website-id', websiteId)
    script.setAttribute('data-auto-track', AUTO_TRACK)
    script.setAttribute('data-do-not-track', DO_NOT_TRACK)
    script.setAttribute('data-cache', CACHE)
    script.setAttribute('async', '')
    script.setAttribute('defer', '')
    // initialize script
    document.head.appendChild(script)
  }, [websiteId])

  const _locale = locale ? JSON.parse(locale) : undefined
  const _currentTags = currentTags ? JSON.parse(currentTags) : undefined

  // const shopifyProducts = dataRaw
  //   ? (JSON.parse(dataRaw ?? '[]') as Product[])
  //   : ([] as Product[])

  const headerRow = header !== undefined && header !== null && (
    <p className='lemon-mt-[16px] lemon-text-center lemon-text-xl lemon-font-bold lemon-tracking-tight lemon-text-gray-900 sm:lemon-text-2xl lg:lemon-text-3xl'>
      {header}
    </p>
  )

  const subheaderRow = subheader !== undefined && subheader !== null && (
    <p className='lemon-mt-[8px] lemon-text-center lemon-text-base lemon-text-gray-700 lg:lemon-text-lg'>
      {subheader}
    </p>
  )

  const gridSkeletonContainer = (
    <div className='lemon-flex lemon-flex-col lemon-items-center'>
      {/* small screen */}
      <div
        id='lemon-ugc-grid-small'
        className='lemon-w-full sm:lemon-hidden lg:lemon-hidden'
      >
        <div
          className={classNames(
            `lemon-inline-grid lemon-w-full lemon-gap-y-[8px] lemon-gap-x-[8px] sm:lemon-gap-x-[12px] sm:lemon-gap-y-[12px]`,
            `lemon-grid-cols-${smallX}`,
          )}
        >
          {Array.from({ length: smallX * smallY }).map((_, i) => (
            <PostGridSkeleton key={`grid-small-skeleton-${i}`} />
          ))}
        </div>
      </div>

      {/* mid screen */}
      <div
        id='lemon-ugc-grid-mid'
        className='lemon-hidden lemon-w-full sm:lemon-block lg:lemon-hidden'
      >
        <div
          className={classNames(
            `lemon-inline-grid lemon-w-full lemon-gap-y-[8px] lemon-gap-x-[8px] sm:lemon-gap-x-[12px] sm:lemon-gap-y-[12px]`,
            `lemon-grid-cols-${midX}`,
          )}
        >
          {Array.from({ length: midX * midY }).map((_, i) => (
            <PostGridSkeleton key={`grid-mid-skeleton-${i}`} />
          ))}
        </div>
      </div>

      {/* large screen */}
      <div id='lemon-ugc-grid-large' className='lemon-hidden lg:lemon-block'>
        <div
          className={classNames(
            `lemon-inline-grid lemon-w-full sm:lemon-gap-x-[12px] sm:lemon-gap-y-[12px] lg:lemon-gap-y-[16px] lg:lemon-gap-x-[16px]`,
            `lemon-grid-cols-${largeX}`,
          )}
        >
          {Array.from({ length: largeX * largeY }).map((_, i) => (
            <PostGridSkeleton key={`grid-small-skeleton-${i}`} />
          ))}
        </div>
      </div>
    </div>
  )

  const rowSkeletonContainer = (
    <ul className='no-scrollbar lemon-flex lemon-space-x-[16px] lemon-overflow-x-scroll lemon-px-[8px]'>
      {Array.from({ length: 10 }).map((_, i) => (
        <PostRowSkeleton key={`grid-small-skeleton-${i}`} />
      ))}
    </ul>
  )

  if (designMode) {
    return (
      <div>
        <div className='lemon-mx-auto lemon-overflow-hidden lemon-py-[16px] lemon-px-[8px] sm:lemon-py-[32px] sm:lemon-px-[16px]'>
          <div className='lemon-mb-[24px]'>
            {headerRow}
            {subheaderRow}
          </div>
          <div>{grid ? gridSkeletonContainer : rowSkeletonContainer}</div>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return grid ? gridSkeletonContainer : rowSkeletonContainer
  }

  if (error) {
    return null
  }

  const posts = data ?? []

  // const posts = []

  // To further integrate with shopify. Not finished yet.
  // const collectionsRow = (
  //   <div className='lemon-relative lemon-m-2'>
  //     <ul className='lemon-flex lemon-flex-row lemon-space-x-4'>
  //       {shopifyProducts?.map(prod => (
  //         <li key={prod.id}>
  //           <a href={`${prod.handle}`}>
  //             <h2>{prod.title}</h2>
  //             <img
  //               src={prod.featured_image}
  //               alt=''
  //               className='lemon-h-20 lemon-w-20 lemon-object-cover'
  //             />
  //           </a>
  //         </li>
  //       ))}
  //     </ul>
  //   </div>
  // )

  const seeMoreButton = (
    <div className='lemon-mt-[16px] lemon-flex lemon-justify-center'>
      <button
        type='button'
        className={classNames(
          'lemon-inline-flex lemon-items-center lemon-rounded-md lemon-border lemon-border-gray-300 lemon-bg-white lemon-px-[16px] lemon-py-[8px] lemon-text-base lemon-font-medium lemon-text-gray-700 lemon-shadow-sm hover:lemon-bg-gray-50 focus:lemon-outline-none focus:lemon-ring-[8px] focus:lemon-ring-indigo-500 focus:lemon-ring-offset-2',
          allElements ? '' : 'lemon-animate-pulse',
        )}
        onClick={() => setAllElements(!allElements)}
      >
        {allElements ? 'もとに戻す' : 'もっと見る'}
      </button>
    </div>
  )

  const gridContainer = (
    <div className='lemon-flex lemon-flex-col lemon-items-center'>
      {/* small screen */}
      <div className='lemon-w-full sm:lemon-hidden lg:lemon-hidden'>
        <div
          className={classNames(
            `lemon-w-full lemon-gap-y-[8px] lemon-gap-x-[8px] sm:lemon-gap-x-[12px] sm:lemon-gap-y-[12px] lemon-inline-grid`,
            `lemon-grid-cols-${smallX}`,
          )}
        >
          {posts
            .slice(0, allElements ? posts.length : smallY * smallX)
            ?.map((post, index) => (
              <PostGridItem
                key={post.mediaId}
                post={post}
                index={index}
                onClick={() => {
                  setInitialSlide(index)
                  setShowDialog(true)
                  panelOpenDuration.current = new Date().getTime()
                }}
              />
            ))}
        </div>
        {posts.length > smallY * smallX && seeMoreButton}
      </div>

      {/* mid screen */}
      <div className='lemon-hidden lemon-w-full sm:lemon-block lg:lemon-hidden'>
        <div
          className={classNames(
            `lemon-w-full lemon-gap-y-[8px] lemon-gap-x-[8px] sm:lemon-gap-x-[12px] sm:lemon-gap-y-[12px] lemon-inline-grid`,
            `lemon-grid-cols-${midX}`,
          )}
        >
          {posts
            .slice(0, allElements ? posts.length : midX * midY)
            ?.map((post, index) => (
              <PostGridItem
                key={post.mediaId}
                post={post}
                index={index}
                onClick={() => {
                  setInitialSlide(index)
                  setShowDialog(true)
                  panelOpenDuration.current = new Date().getTime()
                }}
              />
            ))}
        </div>
        {posts.length > midX * midY && seeMoreButton}
      </div>

      {/* large screen */}
      <div className='lemon-hidden sm:lemon-hidden lg:lemon-block'>
        <div
          className={classNames(
            `lemon-inline-grid lemon-w-full sm:lemon-gap-x-[12px] sm:lemon-gap-y-[12px] lg:lemon-gap-y-[16px] lg:lemon-gap-x-[16px]`,
            `lemon-grid-cols-${largeX}`,
          )}
        >
          {posts
            .slice(0, allElements ? posts.length : largeY * largeX)
            ?.map((post, index) => (
              <PostGridItem
                key={post.mediaId}
                post={post}
                index={index}
                onClick={() => {
                  setInitialSlide(index)
                  setShowDialog(true)
                  panelOpenDuration.current = new Date().getTime()
                }}
              />
            ))}
        </div>
        {posts.length > largeY * largeX && seeMoreButton}
      </div>
    </div>
  )

  const rowContainer = (
    <ul className='no-scrollbar lemon-flex lemon-space-x-[16px] lemon-overflow-x-scroll lemon-px-[8px]'>
      {posts.map((post, index) => (
        <PostRowItem
          key={post.mediaId}
          post={post}
          onClick={() => {
            setInitialSlide(index)
            setShowDialog(true)
            panelOpenDuration.current = new Date().getTime()
          }}
        />
      ))}
    </ul>
  )

  const detailsDialog = (
    <Transition.Root show={showDialog} as={Fragment}>
      <Dialog
        as='div'
        className='lemon-relative lemon-z-[9999]'
        onClose={value => {
          setShowDialog(value)
          // post panel open duration
          const duration = new Date().getTime() - panelOpenDuration.current
          const anyWindow = window as any
          anyWindow.umami?.trackEvent('ugc_panel_open_duration', {
            duration,
            template,
            productId,
            variantId,
            collectionId,
            host,
            path,
            pathType,
            ugcSetId,
          })
          panelOpenDuration.current = undefined
        }}
      >
        <Transition.Child
          // as={Fragment}
          enter='lemon-ease-out lemon-duration-300'
          enterFrom='lemon-opacity-0'
          enterTo='lemon-opacity-100'
          leave='lemon-ease-in lemon-duration-200'
          leaveFrom='lemon-opacity-100'
          leaveTo='lemon-opacity-0'
        >
          <div className='lemon-fixed lemon-inset-0 lemon-block lemon-bg-gray-500 lemon-bg-opacity-75 lemon-transition-opacity'>
            &nbsp;
          </div>
        </Transition.Child>

        <div className='lemon-fixed lemon-inset-0 lemon-z-[9999] lemon-overflow-y-auto'>
          <div className='lemon-flex lemon-min-h-full lemon-items-stretch lemon-justify-center lemon-text-center md:lemon-items-center md:lemon-px-[8px] lg:lemon-px-[16px]'>
            {/* This element is to trick the browser into centering the modal lemon-contents. */}
            <span
              className='lemon-hidden md:lemon-inline-block md:lemon-h-screen md:lemon-align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='lemon-ease-out lemon-duration-300'
              enterFrom='lemon-opacity-0 lemon-translate-y-[16px] md:lemon-translate-y-0 md:lemon-scale-95'
              enterTo='lemon-opacity-100 lemon-translate-y-0 md:lemon-scale-100'
              leave='lemon-ease-in lemon-duration-200'
              leaveFrom='lemon-opacity-100 lemon-translate-y-0 md:lemon-scale-100'
              leaveTo='lemon-opacity-0 lemon-translate-y-[16px] md:lemon-translate-y-0 md:lemon-scale-95'
            >
              <Dialog.Panel className='lemon-flex lemon-w-full lemon-transform lemon-text-left lemon-text-base lemon-transition md:lemon-my-[32px] md:lemon-max-w-[672px] md:lemon-px-[16px] lg:lemon-max-w-[896px]'>
                {/* <div className="lemon-relative lemon-flex lemon-w-full lemon-items-center lemon-overflow-hidden lemon-bg-white lemon-px-[16px] lemon-pt-[56px] lemon-pb-[32px] lemon-shadow-2xl sm:lemon-px-[24px] sm:lemon-pt-[32px] md:lemon-p-[24px] lg:lemon-p-[32px]">
    </div> */}

                <Swiper
                  modules={[Navigation, Pagination, Scrollbar]}
                  navigation
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  slidesPerView={1}
                  initialSlide={initialSlide}
                  centeredSlides
                  onActiveIndexChange={swiper => {
                    const activeIndex = swiper.activeIndex
                    const post = posts[activeIndex]
                    if (post) {
                      const anyWindow = window as any
                      anyWindow.umami?.trackEvent('instagram-post-detail', {
                        postId: post.id,
                        mediaId: post.mediaId,
                        igId: post.igId,
                        username: post.username,
                        template,
                        productId,
                        variantId,
                        collectionId,
                        host,
                        path,
                        pathType,
                        ugcSetId,
                      })
                    }
                  }}
                >
                  {posts?.map(post => (
                    <SwiperSlide key={post.mediaId}>
                      <div className='lemon-relative lemon-flex lemon-w-full lemon-items-center lemon-overflow-hidden lemon-bg-white lemon-px-[16px] lemon-pt-[56px] lemon-pb-[32px] lemon-shadow-2xl sm:lemon-px-[24px] sm:lemon-pt-[32px] md:lemon-p-[24px] lg:lemon-p-[32px]'>
                        <button
                          type='button'
                          className='lemon-absolute lemon-top-[16px] lemon-right-[16px] lemon-text-gray-400 hover:lemon-text-gray-500 sm:lemon-top-[32px] sm:lemon-right-[24px] md:lemon-top-[24px] md:lemon-right-[24px] lg:lemon-top-[32px] lg:lemon-right-[32px]'
                          onClick={() => {
                            setShowDialog(false)
                            // post panel open duration
                            const duration =
                              new Date().getTime() - panelOpenDuration.current
                            const anyWindow = window as any
                            anyWindow.umami?.trackEvent(
                              'ugc_panel_open_duration',
                              {
                                duration,
                                template,
                                productId,
                                variantId,
                                collectionId,
                                host,
                                path,
                                pathType,
                                ugcSetId,
                              },
                            )
                            panelOpenDuration.current = undefined
                          }}
                        >
                          <span className='lemon-sr-only'>Close</span>
                          <XMarkIcon
                            className='lemon-h-[24px] lemon-w-[24px]'
                            aria-hidden='true'
                          />
                        </button>

                        <PostDetailsSlide
                          post={post}
                          shopifyProducts={
                            post.shopifyProducts ? post.shopifyProducts : []
                          }
                          customProducts={
                            post.customProducts ? post.customProducts : []
                          }
                          onProductClick={product => {
                            const anyWindow = window as any
                            anyWindow.umami?.trackEvent(
                              'instagram-post-detail-product-click',
                              {
                                postId: post.id,
                                mediaId: post.mediaId,
                                igId: post.igId,
                                username: post.username,
                                template,
                                productId,
                                variantId,
                                collectionId,
                                host,
                                path,
                                pathType,
                                ugcSetId,
                                clickProductHandle: product.handle,
                                clickProductId: product.id,
                              },
                            )

                            const url = `/products/${product.handle}`
                            document.location.assign(url)
                          }}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )

  return (
    <div>
      <div className='lemon-mx-auto lemon-overflow-hidden lemon-py-[16px] lemon-px-[8px] sm:lemon-py-[32px] sm:lemon-px-[16px]'>
        <div className='lemon-mb-[24px]'>
          {headerRow}
          {subheaderRow}
        </div>
        <div>
          {websiteId !== undefined && !designMode && (
            <>{grid ? gridContainer : rowContainer}</>
          )}
        </div>
      </div>

      {detailsDialog}
    </div>
  )
}

export default UGCRoot
